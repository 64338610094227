import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Card,
  Divider,
  useTheme,
  styled,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Tooltip,
  InputAdornment,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress
} from '@mui/material';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import { ToastContainer, toast } from 'react-toastify';
import UserContext from 'src/context/UserContext';
import SearchIcon from '@mui/icons-material/Search';
import { ManagementServices } from 'src/services/ManagementServices';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import Filter from 'src/content/applications/Users/common/filter';
import { Role } from 'src/Role';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import moment from 'moment';
import UserDetails from '../Shops/UserDetails';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { environment } from 'src/environment/environment';
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
function InvoiceDetails() {
  const theme = useTheme();
  const [invoiceCount, setInvoiceCount] = useState<any>([]);
  const [invoice, setInvoice] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [openPreview, setOpenPreview] = useState(false);
  const [invoiceItemList, setInvoiceItem] = useState<any>([]);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [value, setValue] = useState<any>('All');
  const [billedValue, setBilledValue] = useState<any>('None');
  const [open, setOpen] = useState(false);
  const [offsetIncrease, setOffsetIncrease] = useState(true);
  const [lazyLoadingOffset, seTlazyLoadingOffset] = useState(0);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const initialState = { data: '' };
  const [searchData, setSearchData] = useState<any>(initialState);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const data = {
    searchTag: searchData.data,
    startDate: startValue,
    endDate: endValue,
    billedStatus: billedValue
  };
  const [user] = useContext(UserContext);
  const role = user && user?.role;
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [openUserDetails, SetOpenUserDetails] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState();

  useEffect(() => {
    getAllInvoicesBySearch();
    setCurrentDate(new Date());
  }, [
    page,
    rowsPerPage,
    searchData,
    billedValue,
    startValue,
    endValue
  ]);

  const getAllInvoicesBySearch = () => {
    let inventoryOffset = lazyLoadingOffset;

    if (!ifSearchChanged) {
      inventoryOffset = offsetIncrease
        ? lazyLoadingOffset + 1
        : lazyLoadingOffset - 1;
    }
    ManagementServices.getAllInvoicesBySearchAdmin(
      rowsPerPage,
      data,
      inventoryOffset
    ).then((res) => {
      if (res.success) {
        console.log("res.data new res",res.data[0]?.invoiceList)
        if(ifSearchChanged ){
          const invoiceList = res.data[0]?.invoiceList || [];
          setInvoice(invoiceList);
        }
        else{
          const invoiceList = res.data[0]?.invoiceList || [];
          setInvoice(prevList => [...prevList, ...invoiceList]); 
        }
        setLoading(false);
        seTlazyLoadingOffset(inventoryOffset);
        setIfSearchChanged(false);
       
        const count = res.data[0]?.count || 0;
        setInvoiceCount(count);
       // setInvoice(invoiceList);
      } 
      setIsLoading(false);

    });
  };

  const getAllInvoiceItems = (invoice: any) => {
    ManagementServices.getAllInvoiceItems(invoice._id).then((res) => {
      if (res.success) {
        console.log(res.data);
        const invoiceItems = res.data.invoiceItemList || [];
          
 const updatedItems = invoiceItems.map((item: any) => ({
   ...item,
   customerName: invoice.customerName,
   customerPhoneNo: invoice.customerPhoneNumber,
   shopName: invoice.shopName,
   shopPhoneNumber: invoice.phoneNumber,
   shopOwnerName: invoice.ownerName
 }));
        setInvoiceItem(updatedItems);
        setOpenPreview(true);
      } else {
      }
    });
  };

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      width: '50vw',
      maxWidth: 'none'
    }
  }));

  const handlePreviewOpen = (invoice: any) => {
    getAllInvoiceItems(invoice);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handlePageChange = (event, newPage) => {
    setLoading(true)
    const increaseOffset = page > newPage ? false : true;
    setOffsetIncrease(increaseOffset);
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    seTlazyLoadingOffset(0);
  };

  const handleChange = (event: any) => {
    const selectedvalue = event.target.value;
    const today = new Date();
    setValue(selectedvalue);
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
    switch (selectedvalue) {
      case 'Today':
        setStartValue(new Date());
        setEndValue(new Date());
        setShowCustomDatePicker(false);

        break;
      case 'Last 7 days':
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);
        setEndValue(sevenDaysAgo);
        setStartValue(today);
        setShowCustomDatePicker(false);

        break;
      case 'Last 28 days':
        const twentyEightDaysAgo = new Date(today);
        twentyEightDaysAgo.setDate(today.getDate() - 27);
        setEndValue(twentyEightDaysAgo);
        setStartValue(today);
        setShowCustomDatePicker(false);

        break;
      case 'Custom':
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(true);

        break;
      default:
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(false);

        break;
    }
  };

  const handelBilledAmout = (value: any) => {
    setBilledValue(value);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearchChange = () => {
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
  };
const handleCopy = async (categoryId) => {
  await navigator.clipboard.writeText(categoryId);
  toast('Copied to clipboard');
};
  const handleEditOpen = (invoice : any) => {
    setSelectedInvoice(invoice);
    setOpenPreview(true);
  };
  const deleteConform = (invoiceData: any,index :any) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK',
    cancelButtonText: 'Close'
  }).then((result) => {
    if (result.isConfirmed) {
      ManagementServices.deleteInvoice(invoiceData?._id).then((res) => {
        Swal.fire(
          'Deleted!',
          'Invoice has been deleted.',
          'success'
        ).then((result) => {
          if (result.isConfirmed) {
            const updatedInvoice = [...invoice];
            updatedInvoice.splice(index, 1);
            setInvoice(updatedInvoice);
          //  fetchInvoices();
          }
        });
      }).catch((error) => {
        Swal.fire(
          'Error!',
          'There was an error deleting your file.',
          'error'
        );
      });
    }
  });
};


const handleUserDetailsOpen = (shopID : any) => {
  setSelectedShopId(shopID);
  SetOpenUserDetails(true);
};

const handleUserDetailsClose = () => {
  SetOpenUserDetails(false);
};


const viewInvoice  = (invoiceId) => {
  const url = `https://fanbudget.com/public-invoice-view/${invoiceId}`;
  window.open(url, '_blank');
};

  return (
    <>
      <StyledDialog open={openPreview} onClose={handlePreviewClose}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handlePreviewClose}
        >
          <Typography variant="h4" fontWeight="bold">
            Preview Invoice Details
          </Typography>
        </BootstrapDialogTitle>
        <List sx={{ marginTop: '-40px', marginLeft: '20px' }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopName}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Owner Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopOwnerName}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Contact Number :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopPhoneNumber}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Customer Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.customerName != null
                    ? invoiceItemList[0]?.customerName
                    : '-'}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Customer Contact Number :
                  </Typography>{' '}
                  {invoiceItemList[0]?.customerPhoneNo != null
                    ? invoiceItemList[0]?.customerPhoneNo
                    : '-'}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>

        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Inventory Item</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(invoiceItemList) &&
                  invoiceItemList.map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{item?.productName}</TableCell>
                      <TableCell>
                         {item?.price}
                      </TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                      <TableCell>
                         {item?.amount}
                      </TableCell>
                     
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </StyledDialog>

      <Card>
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ flexWrap: 'wrap' }}
            alignItems="center"
            gap={1}
          >
            <Typography
              variant="h4"
              component="h2"
              m={2}
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold',
              }}
            >
              Invoice Details
            </Typography>
            <Box
              display="flex"
              sx={{ flexWrap: 'wrap', ml: 2, mr: 2 }}
              alignItems="center"
              gap={1}
            >
              <Filter
                billedValue={billedValue}
                handelBilledAmout={handelBilledAmout}
                value={value}
                startValue={startValue}
                endValue={endValue}
                showCustomDatePicker={showCustomDatePicker}
                handleChange={handleChange}
                handleOpen={handleOpen}
                setStartValue={setStartValue}
                setEndValue={setEndValue}
                handleSearchChange={handleSearchChange}
                dropDownHeading="Billed Amount"
                open={open}
                maxDate={currentDate}
              />

              <TextField
                variant="outlined"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchData((prevSearchData) => ({
                    ...prevSearchData,
                    data: e.target.value
                  }));
                  setPage(0);
                  seTlazyLoadingOffset(1);
                  setIfSearchChanged(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
        </>

        <Divider />
        <div style={{ height: 20 }}></div>
        {isLoading ?(
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
        ):(
<>
<TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell width={5}>No</TableCell> */}
                <TableCell>Invoice Number</TableCell>
                <TableCell>Shop Name</TableCell>
                <TableCell>Total value</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Sub Total</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold'
                      }}
                    >
                      No data to display
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                invoice?.map((invoice: any, index) => {
                  return (
                    <TableRow hover key={index}>
                      {/* <TableCell>{ index + 1}</TableCell> */}
                      <TableCell>
                      { index + 1}
                        <Tooltip title="Copy ID" arrow>
                          <IconButton
                            onClick={() => handleCopy(invoice._id)}
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.warning.main
                            }}
                            color="inherit"
                            size="small"
                          >
                            <CopyAllTwoTone fontSize="small" />
                            <ToastContainer
                              position="bottom-right"
                              autoClose={1000}
                              hideProgressBar
                              newestOnTop={false}
                              closeOnClick={false}
                              rtl={false}
                              pauseOnFocusLoss={false}
                              draggable={false}
                              pauseOnHover={false}
                              theme="dark"
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                            component="span"
                            onClick={() => handleUserDetailsOpen(invoice.shopId)}
                            sx={{
                              cursor: invoice.shopName ? 'pointer' : 'default',
                              textDecoration: invoice.shopName ? 'underline' : 'none',
                              color: invoice.shopName ? 'primary.main' : 'text.primary',
                            }}
                          >
                            {invoice.shopName ? invoice.shopName : "-"}
                          </Typography>
                        </TableCell>


                      
                      {/* <TableCell>
                     {invoice?.shopName ? invoice?.shopName : "-"} 
                      </TableCell> */}
                      <TableCell>
                     {invoice?.currency ? invoice?.currency : "LKR"}    {invoice?.total}
                      </TableCell>
                      <TableCell>
                      {invoice?.currency ? invoice?.currency : "LKR"}       {invoice?.discount}
                      </TableCell>
                      <TableCell>
                      {invoice?.currency ? invoice?.currency : "LKR"}      {invoice?.subTotal}
                      </TableCell>
                      <TableCell>
                      {moment(invoice.createdAt).format('MMM Do YYYY')}
                      </TableCell>
                      <TableCell>
                       <Box display="flex" justifyContent="end">
                       <Tooltip title="Preview Invoice" arrow>
                          <IconButton
                            onClick={() => viewInvoice(invoice._id)}
                            sx={{
                              '&:hover': {
                                background: theme.colors.warning.lighter
                              },
                              color: theme.palette.warning.main
                            }}
                            color="warning"
                            size="small"
                          >
                            <ReceiptIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                       <Tooltip title="Preview Invoice items" arrow>
                          <IconButton
                            onClick={() => handlePreviewOpen(invoice)}
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                          >
                            <PreviewTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                   <Tooltip title="Delete Invoice" arrow>
                     <IconButton
                       onClick={() => deleteConform(invoice._id,index)}
                       sx={{
                         '&:hover': {
                           background: theme.colors.error.lighter
                         },
                         color: theme.palette.error.main
                       }}
                       color="inherit"
                       size="small"
                     >
                       <DeleteTwoToneIcon fontSize="small" color="error" />
                     </IconButton>
                   </Tooltip>
                       </Box>
                 </TableCell>
                      
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {invoice.length === 0 ? (
               
                    <Typography
                    >
                     
                    </Typography>
             
              ) : (
        <Box p={2}  display="flex" justifyContent="center">
       {
        invoiceCount > invoice.length && (
          <Button
          variant="contained"
          color="primary"
          onClick={(e) => handlePageChange(e, page + 1)}
          >
          {loading ? 'Loading...' : 'See More'}
        </Button>
        )
       }
          {/* <TablePagination
            component="div"
            count={invoiceCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 30]}
          /> */}
        </Box>)}
</>
        )}
        <UserDetails open={openUserDetails} onClose={handleUserDetailsClose} shopId={selectedShopId}/>
      
      </Card>
    </>
  );
}

export default InvoiceDetails;

