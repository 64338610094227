import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import { Container, Grid, Typography } from '@mui/material';
import AllCategoryTable from './AllShopsTable';
import { useState } from 'react';
import { CategoryDetails } from 'src/models/Category';
import AllAdminUsersTable from './AllShopsTable';
import AllShopsTable from './AllShopsTable';
import ShopCustomer from './customers/ShopCustomer';
import ShopCategory from './categories/ShopCategories';
import { useLocation } from 'react-router-dom';
import PremiumShopsTable from './PremiumShops';


const AllShops = () => {
    const location = useLocation();
    const [AllCategories, setAllCategories] = useState(
        [] as CategoryDetails[]
      );
      const headerText = location.pathname.includes('premium-shops') 
        ? "premium" 
        : "all";

    return (
        <>
            <Helmet>
                <title>Shops - All</title>
            </Helmet>

            
      {/* <PageTitleWrapper>
        <>
          <PageHeader
            AllCategories ={AllCategories}
            setAllCategories={setAllCategories}
          />
        </>
      </PageTitleWrapper> */}

            <Container maxWidth="xl">
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                >
                    
                    <Grid item xs={12} style={{paddingTop: "40px"}}>
                    {headerText === "premium" ? (
                            <PremiumShopsTable/>
                        ) : (
                            <AllShopsTable/>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} style={{paddingTop: "40px"}}>
                        <ShopCategory/>
                    </Grid> */}
                    {/*<Grid item xs={12} style={{paddingTop: "40px"}}>
                        <ShopCustomer/>
                    </Grid> */}
                </Grid>
            </Container>
           
            <Footer />
        </>
    )
}

export default AllShops;