import { Box, Container, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <Box
    component="footer"
    sx={{
      mt: 'auto',
      py: 2,
      px: 3,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Typography variant="body2">
      &copy; 2023 - FanBudget App Admin Dashboard
    </Typography>
    <Typography variant="body2">
      Crafted by{' '}
      <Link href="https://efitosolutions.com/" target="_blank" rel="noopener noreferrer">
        efitosolutions.com
      </Link>
    </Typography>
  </Box>



  );
}

export default Footer;
