import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from './context/UserContext';
import { Role } from 'src/Role';

const VerifyRole: React.FC<{ element; allow  }> = ({
  element,
  allow
}): any => {

  const [user] = useContext(UserContext);
  const role = user && user?.role;

  if (!role) {
    return true;
  } else if (user?.verifiedStatus == 'PENDING') {
    localStorage.clear();
    sessionStorage.clear();
    return <Navigate to={'/sign-in'} />;
  }
  else if (allow && role !== allow) {
    return <Navigate to={'/page-not-found'} />;
  }
  else if (allow && allow==Role.CUSTOMER && user?.isWebAccess && role==Role.CUSTOMER) {
    return element;
  }
  else if (allow && role === allow) {
      switch (role) {
        case Role.SUPER_ADMIN:
          return element;
        case Role.INVENTORY_ADMIN:
          return element;
        default:
           <Navigate to={'/page-not-found'} />;
      }
  }
  else {
    localStorage.clear();
    sessionStorage.clear();
     return <Navigate to={'/sign-in'} />;
  }
};
export default VerifyRole;

