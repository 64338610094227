import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Signin = Loader(lazy(() => import('src/content/pages/SigninPage/signin')));
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const AdminDashboard = Loader(lazy(() => import('src/content/applications/Dashboard')));
const AllUsers = Loader(lazy(() => import('src/content/applications/Users/allUsers')));
const AllActiveUsers = Loader(lazy(() => import('src/content/applications/Users/allActiveUsers')));
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const ShopProfile = Loader(lazy(() => import('src/content/applications/Users/shopProfile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));
const InventoryAdminInvoicesByShopId = Loader(lazy(() => import('src/content/applications/Users/shopProfile/InvoiceByShop')));
const InventoryAdminInventoryByShopId = Loader(lazy(() => import('src/content/applications/Users/shopProfile/InventoryByShop')));
const InventoryAdminCategoriesByShopId = Loader(lazy(() => import('src/content/applications/Shops/categories/ShopCategories')));
const InventoryAdminCustomersByShopId = Loader(lazy(() => import('src/content/applications/Shops/customers/ShopCustomer')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));

import AllCahapters from './content/applications/Chapters/AllChapters/index';
import AllChapterGroups from './content/applications/Chapters/ChapterGroups';
import AllCategories from './content/applications/Categories';
import AllIcons from './content/applications/Icons';
import AllUserCategories from './content/applications/Categories/UserCategories';
import AllTasks from './content/applications/Tasks';
import TemplateCategories from './content/applications/TemplateCategories';
import MainInventory from './content/applications/MainInventory';
import TemplateInventory from './content/applications/TemplateInventories';
import InvoiceView from './content/applications/Invoices';
import AdminUsers from './content/applications/Admin/AdminUsers';
import AdminRoles from './content/applications/Admin/AdminRoles';
import AllShops from './content/applications/Shops';
import PublicInvoiceView from './content/pages/CustomerInvoiceView/invoiceView';
import VerifyRole from 'src/ProtectedRoute'
import AllCustomers from './content/applications/Shops/AdminCustomers/AdminCustomer';
import AllInventory from './content/applications/Shops/AdminInventory/AdminInventory';
import AllInvoice from './content/applications/Shops/AdminInvoice/AdminInvoice';
import { Role } from 'src/Role';

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: '/',
      //   element: <VerifyRole element={<AllUsers />} allow={Role.SUPER_ADMIN} />
      // },
      {
        path: '/',
        element: <VerifyRole element={<AdminDashboard />} allow={Role.SUPER_ADMIN} />
      },
      {
        path: 'analytics',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'sign-in',
    element: <Signin />,
    children: [
      {
        path: '',
        element: <Signin />
      }
    ]
  },
  {
    path: 'public-invoice-view',
    element: <PublicInvoiceView />,
    children: [
      {
        path: '',
        element: <PublicInvoiceView />
      },
      {
        path: '*',
        element: <PublicInvoiceView />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="analytics" replace />
      },
      {
        path: 'analytics',
        element: <VerifyRole element={<Crypto />} allow={Role.SUPER_ADMIN} />
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'analytics',
        element: <VerifyRole element={<Crypto />} allow={Role.SUPER_ADMIN} />
      },
      // {
      //   path: 'dashboard',
      //   element: (
      //     <VerifyRole element={<AdminDashboard />} allow={Role.SUPER_ADMIN} />
      //   )
      // }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="" replace />
      },
      {
        path: 'all-users',
        element: <VerifyRole element={<AllUsers />} allow={Role.SUPER_ADMIN} />
      },
      {
        path: 'all-active-users',
        element: (
          <VerifyRole element={<AllActiveUsers />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'all-user-category',
        element: (
          <VerifyRole
            element={<AllUserCategories />}
            allow={Role.SUPER_ADMIN}
          />
        )
      },
      {
        path: 'all-chapters',
        element: (
          <VerifyRole element={<AllCahapters />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'all-category',
        element: (
          <VerifyRole element={<AllCategories />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'all-chapter-groups',
        element: (
          <VerifyRole element={<AllChapterGroups />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'all-icons',
        element: <VerifyRole element={<AllIcons />} allow={Role.SUPER_ADMIN} />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: (
              <VerifyRole element={<UserProfile />} allow={Role.SUPER_ADMIN} />
            )
          },
          {
            path: 'shop-profile',
            element: (
              <VerifyRole element={<ShopProfile />} allow={Role.SUPER_ADMIN} />
            )
          },
          {
            path: 'settings',
            element: (
              <VerifyRole element={<UserSettings />} allow={Role.SUPER_ADMIN} />
            )
          }
        ]
      },
      {
        path: 'all-tasks',
        element: <VerifyRole element={<AllTasks />} allow={Role.SUPER_ADMIN} />
      }
    ]
  },

  {
    path: 'inventory',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="template-category" replace />
      },
      {
        path: 'template-category',
        element: (
          <VerifyRole
            element={<TemplateCategories />}
            allow={Role.SUPER_ADMIN}
          />
        )
      },
      {
        path: 'main-inventory',
        element: (
          <VerifyRole element={<MainInventory />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'template-inventory',
        element: (
          <VerifyRole
            element={<TemplateInventory />}
            allow={Role.SUPER_ADMIN}
          />
        )
      }
    ]
  },

  {
    path: 'invoice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="all-invoices" replace />
      },
      {
        path: 'all-invoices',
        element: (
          <VerifyRole element={<InvoiceView />} allow={Role.SUPER_ADMIN} />
        )
      }
    ]
  },

  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="admin-users" replace />
      },
      {
        path: 'admin-users',
        element: (
          <VerifyRole element={<AdminUsers />} allow={Role.SUPER_ADMIN} />
        )
      },
      {
        path: 'admin-roles',
        element: (
          <VerifyRole element={<AdminRoles />} allow={Role.SUPER_ADMIN} />
        )
      }
    ]
  },
  {
    path: 'inventory-admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="" replace />
      },
      {
        path: 'dashboard',
        element: (
          <VerifyRole
            element={<AdminDashboard />}
            allow={Role.INVENTORY_ADMIN}
          />
        )
      },
      {
        path: 'shop',
        element: (
          <VerifyRole element={<ShopProfile />} allow={Role.INVENTORY_ADMIN} />
        )
      },
      {
        path: 'invoices',
        element: (
          <VerifyRole
            element={<InventoryAdminInvoicesByShopId />}
            allow={Role.INVENTORY_ADMIN}
          />
        )
      },
      {
        path: 'categories',
        element: (
          <VerifyRole
            element={<InventoryAdminCategoriesByShopId />}
          allow={Role.INVENTORY_ADMIN}
          />
        )
      },
      {
        path: 'customers',
        element: <VerifyRole element={<InventoryAdminCustomersByShopId />} allow={Role.INVENTORY_ADMIN || Role.SUPER_ADMIN} />
      },
      {
        path: 'inventories',
        element: (
          <VerifyRole
            element={<InventoryAdminInventoryByShopId />}
            allow={Role.INVENTORY_ADMIN}
          />
        )
      },

      {
        path: 'admin-roles',
        element: (
          <VerifyRole element={<AdminRoles />} allow={Role.INVENTORY_ADMIN} />
        )
      }
    ]
  },
  {
    path: 'customer',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="" replace />
      },
      {
        path: 'shop',
        element: (
          <VerifyRole element={<ShopProfile />} allow={Role.CUSTOMER} />
        )
      },
      {
        path: 'invoices',
        element: (
          <VerifyRole
            element={<InventoryAdminInvoicesByShopId />}
            allow={Role.CUSTOMER}
          />
        )
      },
      {
        path: 'categories',
        element: (
          <VerifyRole
            element={<InventoryAdminCategoriesByShopId />}
          allow={Role.CUSTOMER}
          />
        )
      },
      {
        path: 'customers',
        element: <VerifyRole element={<InventoryAdminCustomersByShopId />} allow={Role.CUSTOMER} />
      },
      {
        path: 'inventories',
        element: (
          <VerifyRole
            element={<InventoryAdminInventoryByShopId />}
            allow={Role.CUSTOMER}
          />
        )
      },
      {
        path: 'admin-roles',
        element: (
          <VerifyRole element={<AdminRoles />} allow={Role.CUSTOMER} />
        )
      }
    ]
  },
  {
    path: 'shops',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="all-shops" replace />
      },
      {
        path: 'all-shops',
        element: <VerifyRole element={<AllShops />} allow={Role.SUPER_ADMIN} />
      },
      {
        path: 'premium-shops',
        element: <VerifyRole element={<AllShops />} allow={Role.SUPER_ADMIN} />
      },
      // {
      //   path: 'shop-customers',
      //   element: <VerifyRole element={<AllCustomers />} allow={Role.SUPER_ADMIN} />
      // },
      // {
      //   path: 'shop-inventory',
      //   element: <VerifyRole element={<AllInventory />} allow={Role.SUPER_ADMIN} />
      // },
      // {
      //   path: 'shop-invoice',
      //   element: <VerifyRole element={<AllInvoice />} allow={Role.SUPER_ADMIN} />
      // },
      {
        path: 'shop-categories',
        element: <VerifyRole element={<InventoryAdminCategoriesByShopId />} allow={Role.SUPER_ADMIN} />
      }
      // {
      //   path: 'admin-roles',
      //   element: <VerifyRole element={<AdminRoles />} allow={Role.SUPER_ADMIN} />
      // },
    ]
  }
];

export default routes;